import { updateDatalayer, updateEventID } from './utils/tracking_datalayer_utils.js';

export default function tagCommanderListener() {
    function listenTrackingClick(event) {
        const trackingName = 'event_id';
        const eventName = event.target.getAttribute('data-tracking-event');
        updateDatalayer(trackingName, eventName);
        updateEventID(event.target);
    }

    function listenShoppingTool() {
        const setShoppingToolForSearch = () => {
            const search = document.querySelector('[data-tagcommander-shoppingtool][name="search"]');
            if (search) {
                sessionStorage.setItem('TC_SHOPPING_TOOL_DATA', 'moteur_resultat');
            }
        };

        const setAndUseShoppingToolData = (dataValue) => {
            sessionStorage.setItem('TC_SHOPPING_TOOL_DATA', dataValue);
            updateDatalayer('product_shoppingtool', dataValue);
        };

        const handleRedirectionSeoLink = () => {
            if (window.location.search.indexOf('dartycid') >= 1) {
                setAndUseShoppingToolData('campagneexterne');
            }
        };

        const handleClickOnDataShoppingTool = (event) => {
            const shoppingToolAttr = event.target
                .closest('[data-tc-shoppingtool]')
                .getAttribute('data-tc-shoppingtool');
            setAndUseShoppingToolData(shoppingToolAttr);
        };
        setShoppingToolForSearch();

        const getShoppingToolData = sessionStorage.getItem('TC_SHOPPING_TOOL_DATA');
        if (getShoppingToolData == null) {
            setAndUseShoppingToolData('aucunshoppingtool');
        } else {
            updateDatalayer('product_shoppingtool', getShoppingToolData);
        }
        handleRedirectionSeoLink();
        document.addEventListener('click', (event) => {
            if (event.target.matches('[data-tc-shoppingtool] a, [data-tc-shoppingtool] button')) {
                handleClickOnDataShoppingTool(event);
            }
            if (event.target.matches('[data-tracking-click]')) {
                listenTrackingClick(event);
            }
        });
    }

    listenShoppingTool();
}
