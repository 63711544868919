function toggleText(element, activeClass) {
    const el = element;
    const initialText = element.dataset.toggleInitialText;
    const targetText = element.dataset.toggleTargetText;
    el.innerHTML = element.classList.contains(activeClass) ? targetText : initialText;
}

function getToggleTarget(toggler, targetSelector) {
    let target = toggler;
    if (toggler.closest(targetSelector)) {
        target = toggler.closest(targetSelector);
    } else if (document.querySelectorAll(targetSelector)) {
        [target] = document.querySelectorAll(targetSelector);
    }

    return target;
}

function toggle() {
    let target = this;
    const targetSelector = this.dataset.toggleTarget;
    const activeClass = this.dataset.toggleActiveClass;

    if (targetSelector) target = getToggleTarget(this, targetSelector);
    target.classList.toggle(activeClass);
    if (this.dataset.toggleInitialText && this.dataset.toggleTargetText) toggleText(this, activeClass);
}

export default function initToggler() {
    const togglers = document.querySelectorAll('[data-toggle]');
    if (togglers) {
        togglers.forEach((toggler) => {
            toggler.addEventListener('click', toggle);
        });
    }
}
