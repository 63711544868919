import { validateEmail, emailErrorMessage } from './custom_input_email.js';

const inputHasContentClass = 'custom-input__input--has-content';
const statusValidClass = 'custom-input__status--is-valid';
const statusErrorClass = 'custom-input__status--is-error';
const inputErrorClass = 'custom-input--is-error';
const fieldEmptyMessage = 'Champs obligatoire';

export class CustomInput {
    constructor(customInput) {
        this.customInput = customInput;
        [this.input] = this.customInput.querySelectorAll('.custom-input__input');
        this.inputIsRequired = this.input.required;
        this.type = this.input.type;
        [this.errorMessageContainer] = this.customInput.querySelectorAll('.custom-input__error-message');
        [this.statusPicto] = this.customInput.querySelectorAll('.custom-input__status');
    }

    get isRequired() {
        return this.inputIsRequired;
    }

    setErrorState = (isError) => this.customInput.classList.toggle(inputErrorClass, isError);

    showErrorMessage = (message = '') => {
        this.errorMessageContainer.innerHTML = message;
    };

    hideErrorMessage = () => {
        this.errorMessageContainer.innerHTML = '';
    };

    hideValidationPicto = () => this.statusPicto.classList.remove(statusValidClass, statusErrorClass);

    showValidationPicto(isValid) {
        this.statusPicto.classList.toggle(statusValidClass, isValid);
        this.statusPicto.classList.toggle(statusErrorClass, !isValid);
    }

    validateInput() {
        const isEmpty = this.input.value.length === 0;
        let isValidEmail = true;
        this.hideValidationPicto();
        this.hideErrorMessage();
        this.setErrorState(false);

        switch (this.input.type) {
            case 'email':
                isValidEmail = !isEmpty && validateEmail(this.input.value);

                if (!isValidEmail) this.showErrorMessage(emailErrorMessage(this.input, this.isRequired));
                if (!isEmpty) {
                    this.setErrorState(!isValidEmail);
                    this.showValidationPicto(isValidEmail);
                } else {
                    this.setErrorState(true);
                }
                break;
            default:
                if (this.isRequired && isEmpty) {
                    this.setErrorState(true);
                    this.showErrorMessage(fieldEmptyMessage);
                }
                break;
        }
    }

    onChange() {
        this.input.classList.toggle(inputHasContentClass, this.input.value.length > 0);
        this.validateInput();
    }

    init() {
        ['click', 'input'].forEach((event) => {
            this.input.addEventListener(event, () => {
                this.onChange();
            });
        });
    }
}

export default () => {
    const customInputs = document.querySelectorAll('[data-custom-input]');
    if (customInputs)
        customInputs.forEach((customInput) => {
            const thisCustomInput = new CustomInput(customInput);
            thisCustomInput.init();
        });
};
