import MicroModal from 'micromodal';
import Cookies from 'js-cookie';

let customCodic = null;

export function setCodic(codicP) {
    customCodic = codicP;
}

function displayMoreStores() {
    const moreStoresButton = document.querySelector('.more_stores');

    if (!moreStoresButton) return;

    moreStoresButton.addEventListener('click', () => {
        const hiddenStores = document.querySelectorAll('.store.hide');
        hiddenStores.forEach((store, index) => {
            if (index < 3) {
                store.classList.toggle('hide');
            }
        });
    });
}

const generatePopup = (url, width, height) => {
    const params = `width=${width},height=${height},scrollbars=yes,resizable=yes`;
    window.open(url, '', params);
};

const displayErrorMessage = (modal) => {
    const searchbar = modal.querySelector('.searchbar');
    const content = modal.querySelector('.content-list');
    const div = document.createElement('div');
    div.classList.add('searchbar_error');
    div.textContent = 'Veuillez saisir un code postal valide';
    searchbar.parentNode.insertBefore(div, searchbar.nextSibling);
    content.innerHTML = '';
};

const removeErrorMessage = () => {
    const errorElement = document.querySelector('.searchbar_error');
    if (errorElement) {
        errorElement.remove();
    }
};

async function addFavoriteStore(button, storeCode, content) {
    const url = '/nav/extra/ajax/clickcollectaddfavoritestore';
    const formData = new URLSearchParams();
    formData.append('storeCodeToAdd', storeCode);

    const btn = button;

    btn.classList.toggle('js-loading');

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        });
        const data = await response.text();

        if (!data.includes('error')) {
            btn.setAttribute('favorite', '');
        } else {
            btn.textContent = 'Limite atteinte';
            btn.classList.remove('favorite');
            btn.classList.add('error');
        }

        content.classList.remove('js-loading');
    } catch (error) {
        console.error(error);
        throw error;
    }
}

function injectCTADatas(codic, html) {
    // on parse le contenu en HTML pour pouvoir manipuler son DOM
    const parser = new DOMParser();
    const content = parser.parseFromString(html, 'text/html');
    const stores = content.querySelector('.stores');
    const storesCTA = stores.querySelectorAll('.store');
    // on parcours tous les boutons "choisir ce magasin" pour incorporer le basket_update et xsell_codic
    storesCTA.forEach((store) => {
        const cta = store.querySelector('.actions');
        const inputBasketUpdate = cta.querySelector('form input[name="basket_update"]');
        const inputXsellCodic = cta.querySelector('form input[name="xsell_codic"]');
        inputBasketUpdate.value = `${codic}!CC`;
        inputXsellCodic.value = codic;
    });

    return stores.outerHTML;
}

export async function storesDisplay(zip) {
    const content = document.querySelector('#add-clickcollect-popin .content-list');
    const zipCode = zip || Cookies.get('USERZIPCODE') || null;
    const codic = customCodic || document.querySelector('button[data-click-and-collect]').dataset.xsellCodic;
    const $faqButton = document.querySelector('.stores_faq');

    content.innerHTML = '';
    if (!zipCode && !zip) return;
    content.classList.add('js-loading');

    removeErrorMessage();

    try {
        const url = `/nav/extra/ajax/click_and_collect?codic=${codic}&zipCode=${zipCode}`;
        const response = await fetch(url);
        const data = await response.text();
        if (!data.includes(`class="error"`)) {
            // si reponse OK, on injecte les valeur des "basket_update" et "xsell_codic"
            // dans tous les boutons "choisir ce magasin"
            const dataUpdated = injectCTADatas(codic, data);
            content.innerHTML = dataUpdated;
        } else {
            // si la reponse de la requete contient une erreur, on inject la div erreur
            content.innerHTML = data;
        }
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        displayMoreStores();
        const informationsButtons = document.querySelectorAll('.stores .informations');
        const favoritesButtons = document.querySelectorAll('.stores .favorite');
        const selectStoreButtons = document.querySelectorAll('.stores .add_to_cart');

        Array.from(informationsButtons).forEach((button) => {
            button.addEventListener(
                'click',
                () => {
                    const url = `/nav/extra/popup/affiche_magasin?codeStore=${button.dataset.code}&nta=true`;
                    generatePopup(url, 800, 800);
                },
                { once: true }
            );
        });

        $faqButton.addEventListener('click', (event) => {
            event.preventDefault();
            const faqUrl = event.target.href;
            generatePopup(faqUrl, 800, 800);
        });

        Array.from(favoritesButtons).forEach((button) => {
            button.addEventListener(
                'click',
                () => {
                    if (!button.hasAttribute('favorite')) {
                        addFavoriteStore(button, button.dataset.storeCode, content);
                    }
                },
                { once: true }
            );
        });

        Array.from(selectStoreButtons).forEach((button) => {
            button.addEventListener('click', () => {
                const form = button.closest('form');
                form.submit();
            });
        });

        content.classList.remove('js-loading');
    }
}

function clickAndCollectStoreInit() {
    const $modal = document.querySelector('#add-clickcollect-popin');
    if (!$modal) return;
    const input = $modal.querySelector('.input');
    const submit = $modal.querySelector('.submit');

    MicroModal.init({
        onShow: (modal) => {
            if (modal.id === 'add-clickcollect-popin') storesDisplay();
        }
    });

    input.addEventListener('keydown', (event) => {
        removeErrorMessage();

        if (event.key === 'Enter') {
            if (input.value.length !== 5) {
                displayErrorMessage($modal);
            } else {
                storesDisplay(input.value);
            }
        }
    });

    submit.addEventListener('click', () => {
        removeErrorMessage();

        if (input.value.length !== 5) {
            displayErrorMessage($modal);
        } else {
            storesDisplay(input.value);
        }
    });
}

export default clickAndCollectStoreInit;
