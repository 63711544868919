import MicroModal from 'micromodal';
import clickAndCollectStoreInit, { setCodic, storesDisplay } from './click_and_collect_store.js';
import { triggerTracking } from './utils/tracking_datalayer_utils.js';

const bindButtonCC = (buttonCC) => {
    buttonCC.addEventListener('click', () => {
        const { codic } = buttonCC.dataset;
        const hasTracking = buttonCC.getAttribute('data-tracking-click') !== null;

        if (hasTracking) {
            const { trackingName } = buttonCC.dataset;
            const { trackingEvent } = buttonCC.dataset;
            triggerTracking(trackingEvent, trackingName);
        }
        setCodic(codic);
        MicroModal.show('add-clickcollect-popin');
        storesDisplay();
    });
};

export default function buttonsCCListInit() {
    const buttonsCC = document.querySelectorAll('[data-click-and-collect]') || null;

    if (buttonsCC && buttonsCC.length > 0) {
        clickAndCollectStoreInit();

        buttonsCC.forEach((buttonCC) => {
            bindButtonCC(buttonCC);
        });
    }
}
