import { updateDatalayer } from './utils/tracking_datalayer_utils.js';

const trackSearchAutoSuggest = () => {
    const searchAutoSuggest = localStorage.getItem('SEARCH_AUTO_SUGGEST');
    if (searchAutoSuggest) {
        updateDatalayer('search_autosuggest', searchAutoSuggest);
    } else {
        updateDatalayer('search_autosuggest', 'non');
    }
    localStorage.removeItem('SEARCH_AUTO_SUGGEST');
};

export default function tagCommanderSearchInit() {
    trackSearchAutoSuggest();
}
