const btnAttrClickCollect = '[data-click-and-collect]';
const addClickCollectForm = '.js-add-clickcollect-form';
const addClickCollectPopin = 'add-clickcollect-popin';
let iframeUrl;

const getLeadFormanceUrl = () => {
    let url = '/webapp/wcs/stores/controller/DartyInfosMagasin?id=';
    if (document.body.getAttribute('data-avec-refonte-leadformance')) {
        url = '/nav/extra/popup/affiche_magasin?codeStore=';
    }

    return url;
};

const listenAddClickCollect = () => {
    document.addEventListener('click', (event) => {
        const element = event.target.closest(btnAttrClickCollect);

        if (element) {
            const leadformanceUrl = getLeadFormanceUrl();
            const basketUpdate = element.getAttribute('data-basket-update');
            const codics = element.getAttribute('data-xsell-codic');

            event.preventDefault();

            fetch('/nav/extra/basket_add/json?mode=checkMagasin', {
                method: 'POST'
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        element.closest(addClickCollectForm).submit();
                    } else {
                        iframeUrl = `/nav/extra/popup/choix_retrait_magasin?from=catalogue&codics=${codics}&basketUpdate=${basketUpdate}&leadformanceUrl=${leadformanceUrl}`;
                        document
                            .getElementById(addClickCollectPopin)
                            .querySelector('iframe')
                            .setAttribute('src', iframeUrl);
                        MicroModal.show(addClickCollectPopin);
                    }
                });
        }
    });
};

export default function addClickCollectInit() {
    const isPageListe = document.querySelector('#products-list');
    // ce retrait magasin ne concerne que les boutons CC hors liste (refacto à prévoir)
    if (!isPageListe) {
        listenAddClickCollect();
    }
}
